import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { Helmet } from "react-helmet";

// eslint-disable-next-line
export const SecurityPageTemplate = ({ title, heading, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient">
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Security - Kamsa</title>
          <meta property="og:title" content="Security - Kamsa" />
          <meta name="description" content="Read about our commitment to security." />
        </Helmet>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title is-size-1 has-text-weight-bold is-bold-light">
                {heading || title}
              </h1>
              <PageContent className="content" content={content} />
              <div className="block">
                <a className="button is-link is-large" href="/about#contact">
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SecurityPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const SecurityPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <SecurityPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

SecurityPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SecurityPage;

export const securityPageQuery = graphql`
  query SecurityPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title,
        heading
      }
    }
  }
`;
